<template>
  <v-card class="grey lighten-4 pa-5 mt-3">
    <v-row dense>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          :disabled="address_idx == 0"
          v-model="currentAddress.type"
          :items="types"
          item-text="address"
          item-value="id"
          label="Tipo"
          rounded
          outlined
          dense
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          v-model="currentAddress.cep"
          @blur="getAddress"
          label="CEP"
          v-mask="'#####-###'"
          placeholder="xxxxx-xxx"
          append-icon="fas fa-search-location"
          outlined
          rounded
          dense
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="currentAddress.address"
          label="Endereço"
          placeholder="Digite o nome da rua"
          outlined
          rounded
          dense
          :disabled="isDisabled"
          :loading="isLoading"
        />
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-text-field
          v-model="currentAddress.complement"
          label="Complemento"
          placeholder="Complemento"
          outlined
          rounded
          dense
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          v-model="currentAddress.district"
          label="Bairro"
          placeholder="Digite o nome do bairro"
          outlined
          rounded
          dense
          :disabled="isDisabled"
          :loading="isLoading"
        />
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-text-field
          v-model="currentAddress.number"
          label="N°"
          v-mask="'########'"
          outlined
          rounded
          dense
        />
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-select
          v-model="currentAddress.uf"
          label="UF"
          :items="uf != null ? uf : getUf()"
          item-text="sigla"
          item-value="sigla"
          outlined
          rounded
          dense
          :disabled="isDisabled"
          :loading="isLoading"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="currentAddress.city"
          label="Cidade"
          placeholder="Digite o nome da cidade"
          outlined
          rounded
          dense
          :disabled="isDisabled"
          :loading="isLoading"
        />
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-btn
          @click="$emit('remove', address)"
          rounded
          outlined
          color="primary"
        >
          Remover Endereço
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    address: Object,
    address_idx: Number,
  },
  data: () => ({
    uf: null,
    isDisabled: false,
    isLoading: false,
  }),
  computed: {
    currentAddress: {
      get: function () {
        return this.address
      },
      set: function (newValue) {
        this.$emit('setAddress', newValue)
      },
    },
  },
  methods: {
    setAddress(data) {
      this.currentAddress.address = data.address
      this.currentAddress.district = data.district
      this.currentAddress.uf = data.state
      this.currentAddress.city = data.city

      this.$emit('setAddress', this.currentAddress)
    },
    getAddress() {
      this.isDisabled = true
      this.isLoading = true
      this.$api
        .get(`https://ws.apicep.com/cep.json?code=${this.currentAddress.cep}`, {
          timeout: 1000,
        })
        .then((res) => {
          let data = res.data
          this.setAddress(data)
        })
        .catch((err) => {
          if (err.code == 'ECONNABORTED') {
            this.$api
              .get(
                `https://cep.awesomeapi.com.br/json/${this.currentAddress.cep}`
              )
              .then((res) => {
                let data = res.data
                this.setAddress(data)
              })
          } else {
            console.log(err)
          }
        })
        .finally(() => {
          this.isDisabled = false
          this.isLoading = false
        })
    },
    getUf() {
      this.$api
        .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/')
        .then((res) => {
          this.uf = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>
